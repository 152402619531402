import React, { useState, useEffect, useContext, useMemo } from 'react';
import { IonList, IonItem, IonLabel, IonIcon, IonButton } from '@ionic/react';
import { useHistory } from 'react-router-dom';
import { arrowForwardOutline } from 'ionicons/icons';
import notificationBell from '../../assets/notification-bell.svg';
import Header from '../header/Header';
import { ProductTourContext } from '../../global/ProductTourContext';
import '../../styles/firendProfile/FriendSearchHeader.scss';

const FriendSearchComponent = ({ users, handleUserClick, unreadCount }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredUsers, setFilteredUsers] = useState([]);
  const { searchBarRef } = useContext(ProductTourContext);
  const history = useHistory();

  const filterUsers = query => {
    if (!query) {
      setFilteredUsers([]);
      return;
    }
    const lowercasedQuery = query.toLowerCase();
    const filtered = users.filter(
      user =>
        user.name.toLowerCase().includes(lowercasedQuery) || user.username.toLowerCase().includes(lowercasedQuery),
    );
    setFilteredUsers(filtered);
  };

  const debouncedFilterUsers = useMemo(() => {
    let timer;
    return query => {
      clearTimeout(timer);
      timer = setTimeout(() => filterUsers(query), 100);
    };
  }, [users]);

  const handleClick = userId => {
    setSearchQuery('');
    handleUserClick(userId);
  };

  const handleNotification = () => {
    history.push('/app/notificationCenter');
  };

  const headerButtons = [
    <IonButton className="notification " key="Notification" onClick={() => handleNotification()}>
      <IonIcon icon={notificationBell} />
    </IonButton>,
  ];

  useEffect(() => {
    debouncedFilterUsers(searchQuery);
  }, [searchQuery, users]);

  useEffect(() => {
    console.log('Updated unreadCount:', unreadCount);
  }, [unreadCount]);

  return (
    <Header
      forwardRef={searchBarRef}
      searchTerm={searchQuery}
      setSearchTerm={setSearchQuery}
      placeholder="Search opponents..."
      debounce={100}
      btns={headerButtons}
    >
      {unreadCount > 0 && <div className="badge" />}
      {searchQuery && (
        <IonList className="search-list" key={searchQuery}>
          {filteredUsers.map(user => (
            <IonItem key={user.id} lines="full" onClick={() => handleClick(user.id)}>
              <div className="search-user" style={{ width: '100%' }}>
                <IonLabel>
                  {user.name} - {user.username}
                </IonLabel>
                <IonIcon icon={arrowForwardOutline} slot="end" />
              </div>
            </IonItem>
          ))}
        </IonList>
      )}
    </Header>
  );
};

export default FriendSearchComponent;
