import React, { useState, useEffect, useContext, useRef } from 'react';
import {
  IonPage,
  IonContent,
  IonButton,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonTitle,
  IonIcon,
  IonSegment,
  IonLabel,
  IonSegmentButton,
  IonBadge,
} from '@ionic/react';
import '../../../styles/home/notificationModal.scss';
import { closeOutline } from 'ionicons/icons';
import { MessageRepository, Client } from '@amityco/ts-sdk';
import { useHistory } from 'react-router-dom';
import { AppContext } from '../../../global/AppContext';
import { AuthContext } from '../../authentication/AuthContext';
import { login } from '../../../services/amityClient';
import { RecentChatItemLoader } from '../../skeletalLoaders/SkeletalLoaders';
import generateUniqueKey from '../../../UniqueKeyGenerator/UniqueKeyGenerator';
import Notifications from './Notifications';
import RecentChats from '../../chat/RecentChats';

const HomepageNotification = () => {
  const [selectedSegment, setSelectedSegment] = useState('notifications');
  const authCtx = useContext(AuthContext);
  const appCtx = useContext(AppContext);
  const bearerToken = authCtx.tokens.idToken;
  const { unreadCount } = appCtx;
  const history = useHistory();
  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = useState(false);
  const { idData } = useContext(AuthContext);
  const messageSubscriptionRef = useRef(null);
  const liveCollectionRef = useRef(null);
  // eslint-disable-next-line no-unused-vars
  const [messages, setMessages] = useState([]);

  const onClose = () => {
    history.push('/app/home');
  };

  useEffect(() => {
    const ensureLogin = async () => {
      if (authCtx.accessData && authCtx.accessData.sub && bearerToken) {
        await login(authCtx.accessData.sub, authCtx.idData.name, bearerToken);
      }
    };

    ensureLogin();
  }, [authCtx, bearerToken]);

  const ensureClientConnected = async () => {
    if (!Client.isConnected()) {
      try {
        await Client.startSession({ userId: authCtx.userData.id, displayName: authCtx.userData.name });
      } catch (error) {
        console.error('Client connection failed:', error);
      }
    }
  };
  const fetchMessages = async subChannelId => {
    if (messageSubscriptionRef.current === subChannelId) {
      return;
    }

    if (messageSubscriptionRef.current) {
      messageSubscriptionRef.current();
    }

    await ensureClientConnected();

    const liveCollection = MessageRepository.getMessages(
      {
        subChannelId,
        limit: 50,
        reverse: true,
      },
      ({ data: fetchedMessages, error }) => {
        if (error) {
          console.error('Error fetching messages:', error);
          return;
        }

        if (fetchedMessages) {
          const unreadMessages = fetchedMessages.filter(message => message.readCount === 0);
          const friendMessages = unreadMessages.filter(message => message.creatorId !== idData.sub);
          const sortedMessages = friendMessages.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
          setMessages(prevMessages => ({
            ...prevMessages,
            [subChannelId]: sortedMessages,
          }));
        }
      },
    );

    liveCollectionRef.current = liveCollection;
  };

  const handleChatClick = (channelId, displayName) => {
    fetchMessages(channelId);
    history.push(`/chat/${channelId}`, { friendName: displayName });
  };

  const handleNotClick = item => {
    if (item.notification_type === 'challenge') {
      history.push(`/app/profile/`);
    } else {
      console.log('Unhandled notification type:', item.notification_type);
    }
  };

  return (
    <IonPage className="notification-page">
      <IonHeader>
        <IonToolbar className="toolbar">
          <IonTitle className="ion-no-padding">
            <div className="ion-text-wrap">Notification Center</div>
          </IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={onClose}>
              <IonIcon icon={closeOutline} slot="icon-only" />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className="segments">
          <IonSegment
            mode="md"
            className="notification-segment"
            value={selectedSegment}
            onIonChange={e => setSelectedSegment(e.detail.value)}
          >
            <IonSegmentButton value="notifications" className="segment-btn">
              <IonLabel>Notifications</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton value="chat" className="segment-btn">
              <div className="label-badge-container">
                <IonLabel className="chat-label">Chat</IonLabel>
                {unreadCount > 0 && (
                  <IonBadge color="danger" className="recent-badge">
                    {unreadCount}
                  </IonBadge>
                )}
              </div>
            </IonSegmentButton>
          </IonSegment>
        </div>

        {loading ? (
          <div>
            <h2 className="loader-time-header">Today</h2>
            {Array.from({ length: 3 }).map(() => (
              <RecentChatItemLoader key={generateUniqueKey()} />
            ))}
            <h2 className="loader-time-header">Last Week</h2>
            {Array.from({ length: 3 }).map(() => (
              <RecentChatItemLoader key={generateUniqueKey()} />
            ))}
            <h2 className="loader-time-header">Earlier</h2>
            {Array.from({ length: 3 }).map(() => (
              <RecentChatItemLoader key={generateUniqueKey()} />
            ))}
          </div>
        ) : (
          <div className="tab-content">
            {selectedSegment === 'notifications' && <Notifications handleChannelClick={handleNotClick} />}
          </div>
        )}
        {selectedSegment === 'chat' && <RecentChats handleChannelClick={handleChatClick} />}
      </IonContent>
    </IonPage>
  );
};

export default HomepageNotification;
