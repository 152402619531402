import React, { useState, useRef, useContext, createContext, useReducer, useMemo, useEffect } from 'react';
import { IonIcon, IonRouterOutlet, IonTabs, IonTabBar, IonTabButton, IonLabel } from '@ionic/react';
import { Route, useHistory } from 'react-router';

import StartFightModal from '../fight/StartFightModal';
import SettingsModal from '../settingsModal/SettingsModal';
import Home from '../home/Home';
import HomepageNotification from '../home/homepageNotifications/HomepageNotification';
import Sports from '../sports/Sports';
import Restaurant from '../restaurant/Restaurant';
import Profile from '../profile/Profile';
import DefaultLanding from './DefaultLanding';
import FriendProfile from '../friendProfile/FriendProfile';
import FriendsListPage from '../friendProfile/FriendsListPage';
import EditProfile from '../profile/EditProfile';
import ProductTour from '../home/ProductTour/ProductTour';
import MatchmakingContext from '../../global/MatchmakingContext';
import '../../styles/appTabs/AppTabs.scss';
import homeIcon from '../../assets/icons/homeIcon.svg';
import smallGloveIcon from '../../assets/icons/smallGloveIcon.svg';
import mapPinIcon from '../../assets/icons/mapPinIcon.svg';
import profIcon from '../../assets/icons/profileIcon.svg';
import basketballIcon from '../../assets/icons/basketballIcon.svg';
import { AppContext } from '../../global/AppContext';
import { ProductTourContext } from '../../global/ProductTourContext';
import { StartFightContextProvider } from '../fight/StartFightContext';

const contextTemplate = {};
// DO WE NEED THAT FOR ANYTHING ?
// const ACTIONS = {
//   SET_MODAL_REF: 'setModalRef',
// };

const PageContext = createContext({ ...contextTemplate });
const PageDispatchContext = createContext({ dispatch: () => {} });

function AppTabs() {
  const appCtx = useContext(AppContext);
  const history = useHistory();
  const { dispatch: matchmakingDispatch } = useContext(MatchmakingContext);
  const { restaurantButtonRef, fightButtonRef, profileButtonRef, visibility } = useContext(ProductTourContext);

  const settingsRef = useRef();
  const startFightRef = useRef();
  const [isTourActive, setIsTourActive] = useState(false);

  const handleTourComplete = () => {
    setIsTourActive(false);
  };

  useEffect(() => {
    const isFirstVisit = localStorage.getItem('firstVisit') === null;
    if (isFirstVisit) {
      localStorage.setItem('firstVisit', 'false');
      history.push('/app/home');
      setIsTourActive(true);
    }
  }, [history]);

  const [page, dispatch] = useReducer(
    (state, action) => {
      // eslint-disable-next-line no-unused-vars
      const updatedState = { ...state };

      switch (action.action) {
        default: {
          throw new Error('Invalid action');
        }
      }

      // return updatedState;
    },
    { ...contextTemplate },
  );

  // XXX: because headers are located on respective pages the image is loaded multiple times

  const pageContextValue = useMemo(
    () => ({
      ...page,
      modals: {
        settings: settingsRef,
        startFight: startFightRef,
      },
    }),
    [page, settingsRef, startFightRef],
  );

  const dispatchContextValue = useMemo(() => ({ dispatch }), [dispatch]);

  return (
    <PageDispatchContext.Provider value={dispatchContextValue}>
      <PageContext.Provider value={pageContextValue}>
        <StartFightContextProvider>
          <IonTabs className="apptabs-navbar">
            <IonRouterOutlet>
              <Route exact path="/app/home" component={Home} />
              <Route exact path="/app/sports" component={Sports} />
              <Route exact path="/app/restaurant" component={Restaurant} />
              <Route exact path="/app/profile" component={Profile} />
              <Route exact path="/app/profile/edit" component={EditProfile} />
              <Route exact path="/app/friendprofile/:userId" component={FriendProfile} />
              <Route exact path="/app/friendprofile/:userId/friendslist" component={FriendsListPage} />
              <Route exact path="/app/notificationCenter" component={HomepageNotification} />
              <Route component={DefaultLanding} />
            </IonRouterOutlet>
            <IonTabBar className="ion-tabbar" slot="bottom">
              <IonTabButton className="ion-button" tab="home" href="/app/home">
                <IonIcon icon={homeIcon} />
                <IonLabel>Home</IonLabel>
              </IonTabButton>
              <IonTabButton className="ion-button" tab="sports" href="/app/sports">
                <IonIcon icon={basketballIcon} />
                <IonLabel>Sports</IonLabel>
              </IonTabButton>
              <IonTabButton
                id="fight-button"
                ref={fightButtonRef}
                className={`ion-button ${visibility.fightButtonRef ? '' : 'hidden'}`}
                tab="fight"
                onClick={() => {
                  if (appCtx.restaurant) {
                    matchmakingDispatch({ type: 'CLEAR_CHALLENGER' });
                    startFightRef.current.clearInitialState();
                    startFightRef.current.present();
                  } else {
                    alert('Please set a restaurant to fight!');
                  }
                }}
              >
                <div className="outer-circle">
                  <div className="circle">
                    <IonIcon style={{ padding: 0 }} icon={smallGloveIcon} className="glove" />
                    <IonLabel className="fight-label"> Fight </IonLabel>
                  </div>
                </div>
              </IonTabButton>
              <IonTabButton
                id="restaurant-button"
                ref={restaurantButtonRef}
                className={`ion-button ${visibility.restaurantButtonRef ? '' : 'hidden'}`}
                tab="restaurant"
                href="/app/restaurant"
              >
                <IonIcon icon={mapPinIcon} />
                <IonLabel>Venues</IonLabel>
              </IonTabButton>
              <IonTabButton
                id="profile-button"
                ref={profileButtonRef}
                className={`ion-button ${visibility.profileButtonRef ? '' : 'hidden'}`}
                tab="profile"
                href="/app/profile"
              >
                <IonIcon icon={profIcon} />
                <IonLabel>My Fights</IonLabel>
              </IonTabButton>
            </IonTabBar>
          </IonTabs>
        </StartFightContextProvider>
        <StartFightModal modalName="startFight" ref={startFightRef} />
        <SettingsModal modalName="settings" ref={settingsRef} />
        {isTourActive && <ProductTour onComplete={handleTourComplete} />}
      </PageContext.Provider>
    </PageDispatchContext.Provider>
  );
}

export { PageContext };
export default AppTabs;
