import React, { useState } from 'react';
import { IonModal, IonItem } from '@ionic/react';
import { sendReview } from '../../global/request/user';
import '../../styles/contactForms/ReviewForm.scss';

const ReviewForm = ({ isOpen, onClose, bearerToken, setIsOpen }) => {
  const [rating, setRating] = useState(0);
  const [thankYouMessage, setThankYouMessage] = useState(false);

  const subject = 'Rate Our App';

  const handleSubmit = async selectedRating => {
    try {
      const response = await sendReview(bearerToken, subject, `Rating: ${selectedRating}`);
      console.log('Review sent successfully:', response);
      setIsOpen(false);
    } catch (error) {
      console.error('Error sending review:', error);
      if (error.response) {
        console.error('Server response:', error.response);
      }
    }
  };

  const handleStarClick = async star => {
    setRating(star);
    setThankYouMessage(true);
    await handleSubmit(star);
  };

  const handleStarKeyDown = async (e, star) => {
    if (e.key === 'Enter' || e.key === ' ') {
      setRating(star);
      setThankYouMessage(true);
      await handleSubmit(star);
    }
  };

  const onModalClose = () => {
    setIsOpen(false);
    setThankYouMessage(false);
    onClose();
  };

  if (!isOpen) {
    return null;
  }

  return (
    <IonModal isOpen={isOpen} onDidDismiss={onModalClose} cssClass="transparent-modal" backdropDismiss>
      <div className="modal-wrapper">
        {!thankYouMessage ? (
          <>
            <h2>Rate Our App</h2>
            <IonItem className="rating-item" lines="none">
              <div className="stars-container">
                {[1, 2, 3, 4, 5].map(star => (
                  <span
                    key={star}
                    role="button"
                    tabIndex="0"
                    className={`star ${rating >= star ? 'selected' : ''}`}
                    onClick={() => handleStarClick(star)}
                    onKeyDown={e => handleStarKeyDown(e, star)}
                    aria-label={`Rate ${star} star${star > 1 ? 's' : ''}`}
                  >
                    ★
                  </span>
                ))}
              </div>
            </IonItem>
          </>
        ) : (
          <div className="thank-you-message">Thank you for rating our app!</div>
        )}
      </div>
    </IonModal>
  );
};

export default ReviewForm;
