import React, { useState, useContext } from 'react';
import {
  IonModal,
  IonItem,
  IonLabel,
  IonSelect,
  IonSelectOption,
  IonTextarea,
  IonButton,
  IonHeader,
  IonToolbar,
  IonTitle,
} from '@ionic/react';
import { sendReview } from '../../global/request/user';
import { AuthContext } from '../authentication/AuthContext';
import '../../styles/contactForms/ContactForm.scss';

function ContactUsForm({ isOpen, onClose }) {
  const authCtx = useContext(AuthContext);
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = async () => {
    try {
      if (!subject || !message) {
        alert('Please fill out all fields.');
        return;
      }

      const response = await sendReview(authCtx.tokens.idToken, subject, message);
      console.log('Contact Us form submitted successfully:', response);

      onClose();
    } catch (error) {
      console.error('Error sending contact us form:', error);
      if (error.response) {
        console.error('Server response:', error.response);
      }
    }
  };

  return (
    <IonModal isOpen={isOpen} onDidDismiss={onClose} cssClass="contact-us-modal" backdropDismiss>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Contact Us</IonTitle>
        </IonToolbar>
      </IonHeader>
      <div className="contact-us-modal-wrapper">
        <IonItem className="contact-us-item">
          <IonSelect value={subject} placeholder="Pick a topic" onIonChange={e => setSubject(e.detail.value)}>
            <IonSelectOption value="Report a bug">Report a bug</IonSelectOption>
            <IonSelectOption value="Request a feature">Request a feature</IonSelectOption>
            <IonSelectOption value="General feedback">General feedback</IonSelectOption>
          </IonSelect>
        </IonItem>

        <IonItem className="contact-us-item">
          <IonLabel position="stacked">Message</IonLabel>
          <IonTextarea
            value={message}
            placeholder="Write your message here..."
            onIonChange={e => setMessage(e.detail.value)}
          />
        </IonItem>

        <div className="contact-us-buttons-container">
          <IonButton onClick={handleSubmit} expand="block">
            Submit
          </IonButton>
          <IonButton color="danger" onClick={onClose} expand="block">
            Close
          </IonButton>
        </div>
      </div>
    </IonModal>
  );
}

export default ContactUsForm;
