import React, { createContext, useContext, useEffect, useReducer, useRef, useMemo, useState } from 'react';
import { submitFight } from '../../global/request/bet';
import { AuthContext } from '../authentication/AuthContext';
import { AppContext } from '../../global/AppContext';
import { PageContext } from '../appTabs/AppTabs';
// TODO: can separate dispatch and state into separate contexts. can potentially avoid rerenders in components that don't depend on the state but need the dispatch.
const contextTemplate = {
  game: {},
  outcome: {},
  allOutcomeData: [],
  challenger: [],
  pageIndex: 0,
  canGo: [],
  items: [],
  payment: '',
  changedDeliveryAddress: null, // only set if changed within modal, else use appctx.restaurant.address
  submitted: false,
};

export const ACTIONS = {
  SET_INITIAL_STATE: 'setInitialState',
  SET_GAME: 'setGame',
  SET_OUTCOME: 'setOutcome',
  SET_ALL_OUTCOME_DATA: 'setAllOutcomeData',
  CLEAR_CHALLENGER: 'clearChallenger',
  SET_CHALLENGER: 'setChallenger',
  PUSH_PAGE: 'pushPage',
  SET_PAGE_INDEX: 'setPageIndex',
  SET_CAN_GO: 'setCanGo',
  SET_ITEM: 'setItem',
  SET_PAYMENT: 'setPayment',
  SET_ADDRESS: 'setAddress',
  SUBMITTED: 'submitted',
  DEV_SET_STATE: 'devSetState',
  SET_MATCHED_CLICKED: 'setMatchedClicked',
};

/**
 * Context for the start fight flow. Contains the state of the flow and the
 * dispatch function to update the state.
 */

const StartFightContext = createContext({
  ...contextTemplate,
  dispatch: () => {},
});

/**
 * Context provider for the start fight flow. Contains the state of the flow and the
 * dispatch function to update the state. Also contains refs to the modal and nav components.
 * @param {Object} props
 * @param {React.MutableRefObject} props.modal A ref to the modal component.
 * @param {React.MutableRefObject} props.nav A ref to the nav component.
 * @param {React.MutableRefObject} props.reducerRef A ref to the reducer function. The provider mutates this ref.
 * @param {number} props.pageCount The number of pages in the flow.
 * @returns
 */
function StartFightContextProvider({ nav, reducerRef, pageCount, children }) {
  const navRef = useRef();
  const formRef = useRef(null);
  // TODO: remove later
  const authCtx = useContext(AuthContext);
  const appCtx = useContext(AppContext);
  const pageCtx = useContext(PageContext);
  const footerNav = useRef();
  const matchModalRef = useRef();

  const [fight, dispatch] = useReducer(
    (state, action) => {
      let updatedState = { ...state };

      switch (action.action) {
        case ACTIONS.SET_INITIAL_STATE: {
          updatedState = { ...updatedState, ...action.state };
          break;
        }

        case ACTIONS.SET_GAME: {
          updatedState.game = action.payload;
          break;
        }

        case ACTIONS.SET_OUTCOME: {
          updatedState.outcome = action.payload;
          break;
        }

        case ACTIONS.SET_ALL_OUTCOME_DATA: {
          updatedState.allOutcomeData = action.payload;
          break;
        }

        case ACTIONS.CLEAR_CHALLENGER: {
          updatedState.challenger = undefined;
          break;
        }

        case ACTIONS.SET_CHALLENGER: {
          updatedState.challenger = action.challenger;
          break;
        }

        case ACTIONS.SET_PAGE_INDEX: {
          updatedState.pageIndex = action.pageIndex;
          break;
        }
        case ACTIONS.SET_PAYMENT: {
          updatedState.payment = action.payment;
          break;
        }

        case ACTIONS.SET_CAN_GO: {
          updatedState.canGo = [...state.canGo];
          updatedState.canGo[action.index] = action.canGo;
          break;
        }

        case ACTIONS.SET_MATCHED_CLICKED: {
          return { ...state, isMatchedClicked: action.payload };
        }

        case ACTIONS.SET_ITEM: {
          updatedState.items = [...state.items];

          // TODO: can be consolidated by using the array find function
          // map the items id to index within items array
          const indexMap = Object.fromEntries(updatedState.items.map((item, index) => [item.id, index]));

          if (indexMap[action.item.id] !== undefined) {
            if (action.item.amount === 0) {
              updatedState.items.splice(indexMap[action.item.id], 1);
            } else {
              updatedState.items[indexMap[action.item.id]].amount = action.item.amount;
            }
          } else if (action.item.amount !== 0) {
            updatedState.items.push(action.item);
          }

          break;
        }

        case ACTIONS.SET_ADDRESS: {
          updatedState.changedDeliveryAddress = action.payload;
          break;
        }

        case ACTIONS.SUBMITTED: {
          updatedState.submitted = true;
          break;
        }

        case ACTIONS.DEV_SET_STATE: {
          updatedState = action.state;
          break;
        }

        default: {
          throw new Error('Invalid action');
        }
      }

      return updatedState;
    },
    { ...contextTemplate, isMatchedClicked: false },
  );

  const submitStripeForm = async () => {
    if (formRef.current) {
      formRef.current.requestSubmit();
    }
  };

  const [isPaymentComplete, setPaymentComplete] = useState(false);

  useEffect(() => {
    if (nav) {
      navRef.current = nav.current;
    }

    if (reducerRef) {
      reducerRef.current = dispatch;
    }
  }, [nav, reducerRef]);

  const handleSubmit = async () => {
    console.log('handleSubmit called');
    if (appCtx.restaurant.id === 64 && fight.payment === '') {
      if (fight.game && fight.outcome && fight.challenger && fight.items.length > 0) {
        await submitStripeForm();
      }
    } else {
      // menu_item_id and product_id are separate to handle the difference between our storage
      // of item ids as Integers and MealMe item ids as Strings. This is a temporary measure
      // as to not require making new tables and schemas to handle strings and keep other
      // restaurants operational
      const fightData = {
        restaurantId: appCtx.restaurant.id,
        items: fight.items.map(item => ({
          menu_item_id: Number.isInteger(item.id) ? item.id : 0,
          quantity: item.amount,
          price: item.price,
          name: item.name,
          product_id: Number.isInteger(item.id) ? '' : item.id,
        })),
        makerOutcome: fight.outcome.id,
        takerId: fight.challenger.id,
        payment: fight.payment,
        address: fight.changedDeliveryAddress || appCtx.restaurant.address,
        sport: fight.game.sport,
        event: fight.game.event,
      };

      console.log('Creating new Fight with data:', fightData);
      await submitFight(authCtx.tokens.idToken, fightData);
      fight.submitted = false;
      setTimeout(() => pageCtx.modals.startFight.current.dismiss(), 5000);
    }
  };

  useEffect(() => {
    console.log('StartFightContext: Current state after update', fight);
    if (
      fight.submitted &&
      fight.pageIndex === 3 &&
      fight.game &&
      fight.outcome &&
      fight.challenger &&
      fight.items.length > 0
    ) {
      handleSubmit();
    }
  }, [fight, contextTemplate]);

  const memoizedValue = useMemo(
    () => ({
      ...fight,
      isPaymentComplete,
      setPaymentComplete,
      pageCount,
      navRef,
      dispatch,
      formRef,
      submitStripeForm,
      footerNav,
      matchModalRef,
    }),
    [fight, isPaymentComplete, setPaymentComplete, pageCount, navRef, dispatch, formRef, submitStripeForm],
  );

  return <StartFightContext.Provider value={memoizedValue}>{children}</StartFightContext.Provider>;
}

export { StartFightContext, StartFightContextProvider };
