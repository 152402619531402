import { IonButton, IonCheckbox, IonContent, IonLabel, IonPage, IonText, IonIcon, useIonRouter } from '@ionic/react';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { chevronBackOutline } from 'ionicons/icons';

import logoIcon from '../../assets/tmp_logo_icon.svg';
import { confirmCode, resendCode, signin, checkUnconfirmedAndSignUp } from '../../global/request/auth';
import { updateProfile } from '../../global/request/user';
import '../../styles/authentication/SignUp.scss';
import MessageOverlay from '../fight/MessageOverlay';
import { AuthDispatchContext } from './AuthContext';
import EntryField from './EntryField';
import SixDigitInput from './SixDigitInput';
// import usePushNotifications from '../notifications/PushNotifications';

function SignUp() {
  const router = useIonRouter();

  const authCtx = useContext(AuthDispatchContext);

  const overlayRef = useRef();
  const [overlayMsg, setOverlayMsg] = useState('');

  const [firstname, setFirstname] = useState(null);
  const [lastname, setLastname] = useState(null);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [phone, setPhone] = useState(null);
  const [formattedPhone, setFormattedPhone] = useState('');
  const [privacypolicy, setPrivacyPolicy] = useState(false);
  const [tos, setTos] = useState(false);
  const [showConfirmCode, setShowConfirmCode] = useState(false);
  const [code, setCode] = useState(null);
  const [isLongEnough, setIsLongEnough] = useState(false);
  const [hasNumber, setHasNumber] = useState(false);
  const [referralId, setReferralId] = useState('');

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const referalId = queryParams.get('referral_id');
    if (referalId) {
      setReferralId(referalId);
    }
  }, [referralId]);

  const isValidEmail = emailAddress => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(emailAddress);
  };

  const isValidPassword = userPassword => {
    const minLength = 8;
    return userPassword.length >= minLength;
  };

  const handleSubmit = async event => {
    event.preventDefault();
    if (!isValidEmail(email)) {
      // TODO if email not valid
      return;
    }
    let signupResponse;
    try {
      // eslint-disable-next-line no-unused-vars
      signupResponse = await checkUnconfirmedAndSignUp({
        email,
        password,
        phone,
      });
      setShowConfirmCode(true);
    } catch (error) {
      setOverlayMsg(error.message);
      overlayRef.current.present();
    }
  };
  const handleConfirmCode = async event => {
    event.preventDefault();
    try {
      await confirmCode({
        email,
        password,
        code,
      });

      const response = await signin({
        email,
        password,
      });
      authCtx.dispatch({ action: 'storeAuthResult', authResult: response });

      await updateProfile(response.tokens.idToken, {
        addresses: [],
        address_set: false,
        birthdate: '',
        delivery_restaurant: '',
        delivery_restaurant_id: '',
        fav_sports: '',
        icon: '',
        user_id: response.tokens.idToken,
        // eslint-disable-next-line prefer-template
        name: firstname + ' ' + lastname,
        phone_number: phone,
        username: firstname.slice(0, 1) + lastname,
        referal_id: referralId,
        email,
        friends: '',
        // storing invalid latitude and longitude numbers upon creation
        latitude: 100.0,
        longitude: 200.0,
        devices: [],
        sent_review: false,
      });

      // usePushNotifications();
      router.push('/app/home');
    } catch (error) {
      console.error(error);
      setOverlayMsg(error.message);
      overlayRef.current.present();
    }
  };

  const handleResendCode = async e => {
    e.preventDefault();
    await resendCode({
      email,
    });
    setOverlayMsg('Verification code has been resent!');
    overlayRef.current.present();
  };

  const handlePhoneNumber = inputNumber => {
    // eslint-disable-next-line prefer-template
    const cleaned = '' + inputNumber;
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    return `${match[1]}-${match[2]}-${match[3]}`;
  };

  const handleButtonClick = () => {
    const phoneNumber = handlePhoneNumber(phone);
    setFormattedPhone(phoneNumber);
  };

  const handleCodeInput = processedCode => {
    setCode(processedCode);
  };

  useEffect(() => {
    setIsLongEnough(password.length >= 8);
    setHasNumber(/\d/.test(password));
  }, [password]);

  const getClassIfTrue = condition => (condition ? 'item green' : 'item');

  return (
    <IonPage>
      <IonContent id="signup-page">
        {!showConfirmCode ? (
          <div id="signup-content">
            <div className="signup-header">
              <IonButton onClick={() => router.push('/signin', 'back')} fill="clear" className="signup-back-button">
                <IonIcon slot="icon-only" icon={chevronBackOutline} className="signup-back-icon" />
              </IonButton>
              <img src={logoIcon} id="logo-icon" alt="FoodFight Logo" />
            </div>
            <h2>Create an Account</h2>
            <form id="entry-options" onSubmit={handleSubmit}>
              <p>First Name</p>
              <EntryField placeholder="John" type="text" setter={setFirstname} value={firstname} />
              <p>Last Name</p>
              <EntryField placeholder="Doe" type="text" setter={setLastname} value={lastname} />
              <p>Email</p>
              <EntryField placeholder="Johndoe@email.com" type="email" setter={setEmail} value={email} />
              <p>Password</p>
              <EntryField placeholder="***********" type="password" setter={setPassword} value={password} />
              <div className="password-req">
                Requirements:
                <ul>
                  <li id="password-eight-letters" className={getClassIfTrue(isLongEnough)}>
                    Password must be at least 8 characters long
                  </li>
                  <li id="password-number" className={getClassIfTrue(hasNumber)}>
                    At least contain a number
                  </li>
                </ul>
              </div>
              <p>Phone Number</p>
              <EntryField
                placeholder="312-***-****"
                type="tel"
                value={phone}
                setter={val => setPhone(val.replace(/\D/g, '').slice(0, 10))}
              />
              <div id="agreements">
                {/* TODO: add links to tos and privacy policy */}
                <div>
                  <IonCheckbox name="tos" checked={tos} onIonChange={() => setTos(!tos)} />
                  <IonLabel>
                    I agree to <a href="https://www.getfoodfight.com/">FoodFight&apos;s Terms and Services</a>
                  </IonLabel>
                </div>
                <div>
                  <IonCheckbox
                    name="privacypolicy"
                    checked={privacypolicy}
                    onIonChange={() => setPrivacyPolicy(!privacypolicy)}
                  />
                  <IonLabel>
                    I agree to <a href="https://www.getfoodfight.com/">FoodFight&apos;s Privacy Policy</a>
                  </IonLabel>
                </div>
              </div>
              <IonButton
                id="signup-btn"
                shape="round"
                expand="block"
                type="submit"
                onClick={handleButtonClick}
                disabled={
                  !(
                    firstname &&
                    lastname &&
                    isValidEmail(email) &&
                    isValidPassword(password) &&
                    phone &&
                    phone.length === 10 &&
                    tos &&
                    privacypolicy
                  )
                }
              >
                Create an Account
              </IonButton>
            </form>
          </div>
        ) : (
          <div id="signup-content">
            <div className="signup-header">
              <IonButton onClick={() => setShowConfirmCode(false)} fill="clear" className="signup-back-button">
                <IonIcon slot="icon-only" icon={chevronBackOutline} className="signup-back-icon" />
              </IonButton>
              <img src={logoIcon} id="logo-icon" alt="FoodFight Logo" />
            </div>
            <h2>Verify your account</h2>
            <p>We have sent a code to {formattedPhone}</p>
            <form id="entry-options" onSubmit={handleConfirmCode}>
              <SixDigitInput onCodeChange={handleCodeInput} />

              <IonText>
                Haven&apos;t recieved code yet? <br />
                <span id="resend-btn" role="button" tabIndex={0} onClick={handleResendCode}>
                  Resend
                </span>
              </IonText>

              <IonButton
                id="signup-btn"
                shape="round"
                expand="block"
                type="submit"
                disabled={!(code && code.length === 6)}
              >
                Next
              </IonButton>
            </form>
          </div>
        )}
        <MessageOverlay blur ref={overlayRef} msg={overlayMsg} />
      </IonContent>
    </IonPage>
  );
}

export default SignUp;
