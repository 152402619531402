import React, { forwardRef, useEffect, useImperativeHandle, useState, useContext } from 'react';
import { IonCard, IonCardContent, IonItem, IonButton, IonIcon } from '@ionic/react';
import { addOutline, removeOutline } from 'ionicons/icons';

import { StartFightContext } from '../StartFightContext';

import '../../../styles/fight/items/ItemCard.scss';

/**
 * Simple component representing a single item card in the item selection list.
 * @param {Object} props
 * @param {Object} props.item The item to display in the card.
 * @param {number} props.item.id The id of the item.
 * @param {string} props.item.name The name of the item.
 * @param {number} props.item.price The price of the item.
 * @param {number} props.item.amount The initial amount of the item.
 * @param {boolean} props.showTotal Whether or not to show the total rather than the price.
 * @param {React.MutableRefObject} ref A ref to the item card component.
 * @returns
 */
// eslint-disable-next-line no-unused-vars
function ItemCard({ item, showTotal, ...props }, ref) {
  const startFightCtx = useContext(StartFightContext);
  const [itemAmount, setItemAmount] = useState(item.amount ?? 0);

  useEffect(() => {
    if (startFightCtx.items[item.name] !== itemAmount) {
      startFightCtx.dispatch({
        action: 'setItem',
        item: {
          id: item.id,
          name: item.name,
          price: item.price,
          amount: itemAmount,
        },
      });
    }
  }, [itemAmount]);

  useImperativeHandle(ref, () => ({
    getItemAmount: () => itemAmount,
  }));

  return (
    <IonCard ref={ref} className="item-card">
      <IonCardContent>
        <div className="item-card-info">
          <p>{item.name}</p>
          {/* <p>${showTotal ? item.price * item.amount : item.price}</p> */}
        </div>
        {item.price !== 0.0 && (
          <div className="price">
            <p>${(item.price / 100.0).toFixed(2)}</p>
          </div>
        )}

        <IonItem>
          <IonButton slot="start" fill="clear" onClick={() => setItemAmount(old => Math.max(old - 1, 0))}>
            <IonIcon icon={removeOutline} slot="icon-only" />
          </IonButton>
          {/* <IonInput
            type="number"
            value={itemAmount}
            fill="solid"
            className="amount-input"
            disabled={true}
            style={{ opacity: 1 }}
          /> */}
          <p
            className="amount-input"
            style={{
              backgroundColor: 'white',
              borderRadius: '0.5rem',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {itemAmount}
          </p>
          <IonButton slot="end" fill="clear" onClick={() => setItemAmount(old => Math.max(old + 1, 0))}>
            <IonIcon icon={addOutline} slot="icon-only" />
          </IonButton>
        </IonItem>
      </IonCardContent>
    </IonCard>
  );
}

export default forwardRef(ItemCard);
