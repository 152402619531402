import React, { useState, useEffect, useContext } from 'react';
import { AuthContext } from '../authentication/AuthContext';
import ReviewForm from './ReviewForm';
import { getProfile } from '../../global/request/user';

const ReviewManager = () => {
  const authCtx = useContext(AuthContext);
  const [showReviewForm, setShowReviewForm] = useState(false);
  const [hasReviewed, setHasReviewed] = useState(false);

  useEffect(() => {
    let timerHandler;

    const fetchProfile = async () => {
      if (!authCtx.loggedIn || !authCtx.tokens.idToken) return;

      try {
        const profileData = await getProfile(authCtx.tokens.idToken);
        if (profileData.review) {
          setHasReviewed(true);
        } else {
          timerHandler = setTimeout(() => {
            setShowReviewForm(true);
          }, 900000);
        }
      } catch (error) {
        console.error('Error fetching profile data:', error);
      }
    };

    if (authCtx.loggedIn) {
      fetchProfile();
    }

    return () => {
      if (timerHandler) {
        clearTimeout(timerHandler);
      }
    };
  }, [authCtx.loggedIn, authCtx.tokens.idToken]);

  if (!authCtx.loggedIn || hasReviewed) {
    return null;
  }

  return (
    showReviewForm && (
      <ReviewForm
        isOpen={showReviewForm}
        onClose={() => setShowReviewForm(false)}
        bearerToken={authCtx.tokens.idToken}
        setIsOpen={setShowReviewForm}
      />
    )
  );
};

export default ReviewManager;
